import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    containerWrap: {
        width: '100%',
        height: '85vh',
        display: 'flex',
        justifyContent: 'center',
   },
    mainContainer: {
        flex: 1,
        display: 'flex',
        padding: '2rem 1rem'
    },
    
});

const LoginPageLayout = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.containerWrap}>
            <div className={classes.mainContainer}>
            {props.children}
            </div>
        </div>
    )
};

export default LoginPageLayout;