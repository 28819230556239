Number.prototype.toFixedOrInteger = function(digits)
{

    var data = Number.parseFloat(this)

    if (Number.isInteger(data))
    {
        return this.toString()
    } else {
        return this.toFixed(digits)
    }
}
