import {_request,getRequest} from './Axios'

const RiderClient = {
    
    post: (FormData,token) => {
    
        return _request("/rider","post",FormData,{
            Authorization: "Bearer " + token
         }).catch((error) => {
            if (error.response) {
                console.log(error.response.data)
                throw new Error(error.response.data.error.message);
            } else {
                throw new Error(error.message);
            }
         })
    },

    upload: (FormData,token) => {
        return _request("/upload","post",FormData,{
            Authorization: "Bearer " + token
         });
    },

    // postFillForm: (FormData) => {
    //     return _request("/question/form","post",FormData)
    // },

    // postSubmit: (FormData,token) => {
    //     return _request("/question/submit","post",FormData, {
    //         Authorization: "Bearer " + token
    //     })
    // },

    delete:(id,token) => {
        return _request(`/rider/${id}`,"delete",null,{
            Authorization: "Bearer " + token
        })
    },

    putUpdate: (id,data,token) => {
        return _request(`/rider/${id}`,"put",data, {
            Authorization: "Bearer " + token
        })
    },

    getList: (token,params) => {
        return getRequest("/rider",{
            Authorization: "Bearer " + token
        },params).catch((error) => {
            console.log(error);
        })
    },


    getAlertVideos: (token,id,params) => {
        return getRequest(`/rider/alertvideos/${id}`,{
            Authorization: "Bearer " + token
        },params).catch((error) => {
            console.log(error);
        })
    },

    getPortalData: (email,token) => {
        return getRequest(`/rider/portal/${email}`,{
            Authorization: "Bearer " + token
        }).catch((error) => {
            console.log(error);
        })
    },

    sendSOSEmail: (data, token) => {
        return _request("/beacon/sendSOS","post",data,{
            Authorization: "Bearer " + token
         });
    },

    getSafetyProfile: (token,params) => {
        return getRequest(`/rider/safetyprofile`,{
            Authorization: "Bearer " + token
        },params).catch((error) => {
            console.log(error);
        })
    },


    postCheckField:(FormData,token) => {
        return _request("/rider/checkfield","post",FormData,{
            Authorization: "Bearer " + token
         });
    },

    getStatus: (token,params) => {
        return getRequest("/rider/status",{
            Authorization: "Bearer " + token
        },params).catch((error) => {
            console.log(error);
        })
    },
    // {{baseurl}}/rider/freeriders?_id=60f8e60f36cd801d9f30f151
    getFreeRiderList:(token,params) => {
        return _request("/rider/freeriders",{
            Authorization: "Bearer " + token
        },params).catch((error) => {
            console.log(error);
        })
    },


    getOne: (id,token) => {
        return getRequest(`/rider/${id}`,{
            Authorization: "Bearer " + token
        });
    }

   
}
export default RiderClient