import React,{useState} from 'react'
import Auth from './client/Auth'
import { useCookies } from 'react-cookie';
import {useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, IconButton, FormControl, Typography } from '@material-ui/core'
import LoginPageLayout from '../layout/users';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm, Controller } from 'react-hook-form';
import AlertMessage from '../components/forms/message';
import logo from '../assets/images/logo.png';
import { getExpireTime } from '../_helpers/cookies.functions';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width: '100%',
        maxWidth: '550px',
        position: 'relative',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    inputWrap: {
        marginBottom: theme.spacing(3),
    },
    input: {
        margin: theme.spacing(0),
        marginBottom: theme.spacing(0),
        color: 'white',
        background: theme.palette.background.input,
        border: 'none',
        padding: '5px 12px',
        borderRadius: '5px',
        fontSize: '1.25em',
        '&::-webkit-input-placeholder': {
            color: theme.palette.text.light,
        }
    },
    label: {
        color: theme.palette.text.light,
        display: 'block',
        margin: '8px 16px',
        fontWeight: 'normal'
    },
    button: {
        padding: '12px 40px'
    },
    welcome: {
        fontSize: '1rem',
        marginBottom: '2rem',
    },
    header: {
        fontSize: '1.75em',
        fontWeight: '400',
        color: theme.palette.common.white,
    },
    small: {
        fontSize: '0.85em',
        textAlign: 'right',
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    },

}));

const defaultValues = {
    email: '',
    password: '',
}
const defaultMessage = {
    title: null,
    content: null,
    type: null, //green for success, red for error
    open: false,
}
function Login({token}) {

    const classes = useStyles();
    const history = useHistory();
    const [cookies, setCookie,removeCookie] = useCookies(['token']);
    const [open, setOpen] = useState(false);
    const { handleSubmit, control, setError,clearError,formState: { errors }, watch } = useForm(defaultValues);
    const [message, setMessage] = useState({
        ...defaultMessage
    });

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = (target) => {
        if (target === 'reset') {
            setOpen(false);
        } else if (target === 'alert') {
            setMessage({
            ...defaultMessage
        });
        }
    };

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const checkEmail = async (email) => {
        if (validateEmail(email)) {
            try {
                let data = await Auth.checkEmail({email});
                if (data.status === "success") {
                    return false
                } else {
                    return true
                }
            } catch(error) {
                console.log(error.message);
                return true
            }
        } else {
            return true
        }
    }


    const onFormSubmit = data => {
     
        const { email,password } = data;
        Auth.login({email,password}).then(({token,data}) => {
            setCookie('token', token, { path: '/', expires: getExpireTime()});
            setCookie('userdata', data, { path: '/', expires: getExpireTime()});
            setTimeout(() => { 
                history.push("/");
            }, 1000);
        }).catch((e) =>  {
            setMessage({title: 'Failed', content:`${e.message}`, type:'red',open:true})
        });
    }

    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
        <form onSubmit={handleSubmit(onFormSubmit)}>
        <LoginPageLayout token={cookies.token}>
            <Box className={classes.mainContainer}>
                <Box textAlign={"center"} py={3}>
                    <Typography className={classes.welcome}>Welcome to</Typography>
                    <img src={logo} alt="Rider Dome" width="200" height="41" />
                </Box>
                <Box flex={1} display="flex" flexDirection="column" justifyContent={"center"}>
                    <Box textAlign="center" mb={3}>
                        <Typography as="h1" variant="h1" className={classes.header}>Log In</Typography>
                    </Box>
                    <FormControl fullWidth className={classes.inputWrap}>
                        <label className={classes.label}>Email Address</label>
                        <Controller 
                            control={control}
                            name="email"
                            rules={{
                                required: true,
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                validate:async (a) => await checkEmail(a)
                            }}
                            render={({ field }) =>
                            <InputBase
                                className={classes.input}
                                id="email"
                                type="email"
                                {...field}
                                />}
                        />
                        <div className={classes.error}>
                            {errors.email?.type === 'required' && "*Required"}
                            {errors.email?.type === 'pattern' && "Invalid email address"}
                            {errors.email?.type === 'validate' && "Email does not exist"}
                        </div>
                        </FormControl>
                        <FormControl fullWidth className={classes.inputWrap}>
                        <label className={classes.label}>Password</label>
                        <Controller 
                            control={control}
                            name="password"
                            rules={{
                                required: true,
                                minLength: 8,
                            }}
                            render={({ field }) =>
                            <InputBase
                                className={classes.input}
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                {...field}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />}
                            />
                        <div className={classes.error}>
                            {errors.password?.type === 'required' && "*Required"}
                            {errors.password?.type === 'minLength' && "At least 8 characters"}
                            {errors.password?.type === 'manual' && errors.password.message}
                        </div>
                        </FormControl>
                    </Box>
                    <Box>
                    <FormControl fullWidth>
                        <Button type="submit" className={classes.button} variant="contained" color="primary">Log In</Button>
                    </FormControl>
                    </Box>
                    
            </Box>
        </LoginPageLayout>
        </form>
        {message.open &&
        <AlertMessage
            open={message.open}
            close={()=>handleClose('alert')}
            title={message.title}
            titleStyle={message.type}
            ariaLabelledby="login-message"
            ariaDescribedby="login-content">
                <Box textAlign="center">
                    {message.content}
                    {message.type === 'red' ? 
                        <Box p={3}><Button variant="outlined" color="secondary" className={classes.button} onClick={()=>handleClose('alert')}>OK</Button></Box>
                    : null}
                </Box>
        </AlertMessage>
        }
    </>
    )
}

export default Login
