import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
});

const _request = (url,method,data,headers) => {
    return instance({method,headers,url,data});
}

const getRequest = (url,headers,params = {}) => {
    return instance.get(url,{headers,params});
}

const putRequest = (url,headers,data) => {
    return instance.post(url,data,{headers});
}

const deleteRequest = (url,headers,params = {}) => {
    return instance.delete(url,{headers,params});
}

export {_request,getRequest,putRequest, deleteRequest}

