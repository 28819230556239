import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../theme/theme';
import CustomSwitch from '../components/switch';
import { Box, Typography, Slider, Button } from '@material-ui/core';
import RiderClient from '../pages/client/Rider'  
import { CircularProgress } from '@material-ui/core';
import AlertMessage from "../components/forms/message";

const useStyles = makeStyles({
    header: {
        flexGrow: 1,
        margin: '16px 32px',
        '& *': {
            marginRight: '8px',
        }
    },
    subheading: {
        fontSize: '1.25rem',
        fontWeight: '500',
        color: theme.palette.text.header,
        lineHeight: '1em'
    },
    tabHeader: {
        height: '50px',
        paddingTop: '10px',
        // paddingLeft: theme.spacing(3),
    },
    root: {
        borderRadius: '15px',
        backgroundColor: theme.palette.background.primary,
        padding: theme.spacing(2,1),
        margin: theme.spacing(0.5,1),
        color: theme.palette.text.header,
    },
});

const defaultMessage = {
    title: null,
    content: null,
    type: null,
    open: false,
}

const Alerts = ({ rider, token, cookies }) => {
    const [alertSettings, setAlertSettings] = useState({CollisionAlertSensitivity: 0, SafeDistanceAlertSensitivity:0, BlindSpotSound: 'no_sound', ReportAlerts: 0, bike_id: ''});
    const [status, setStatus] = useState('')
    const [message, setMessage] = useState({ ...defaultMessage })

    useEffect(() => {
        getRidersData()
    }, [])
    
    const getRidersData = () => {
        RiderClient.getPortalData(cookies.userdata?.user?.email, token).then((rider) => {
            setAlertSettings({
                CollisionAlertSensitivity: rider.data.CollisionAlertSensitivity,
                SafeDistanceAlertSensitivity: rider.data.SafeDistanceAlertSensitivity,
                BlindSpotSound: getBlindSpotNumber(rider.data.BlindSpotSound),
                ReportAlerts: rider.data.ReportAlerts,
                bike_id: rider.data.bike_id
            })
        }).catch(e=>console.log(e.message))
    }

    const classes = useStyles();
    const marks = [
        {
          value: 0,
          label: 'Low',
        },
        {
          value: 1,
          label: 'Medium',
        },
        {
          value: 2,
          label: 'High',
        },
    ];
    
    const blindSpotSound = [
        {
          value: 0,
          label: 'Silent',
        },
        {
          value: 1,
          label: 'Slient when stationery',
        },
        {
          value: 2,
          label: 'Always',
        },
    ];

    const getBlindSpotValue = (value) => {
        let result = ""
        if (value === 0) {
            return result = 'no_sound'
        } else if (value === 1) {
            result = 'no_sound_during_stop'
        } else if (value === 2) { 
            result = 'always'
        }
        return result
    }

    const getBlindSpotNumber = (value) => { 
        let result = ""
        if (value === 'no_sound') {
            return result = 0
        } else if (value === 'no_sound_during_stop') {
            result = 1
        } else if (value === 'always') { 
            result = 2
        }
        return result
    }

    const handleChangeSensitivity = (event, value) => { 
        setAlertSettings({...alertSettings, 'CollisionAlertSensitivity': value})
    }

    const handleChangeDistance = (event, value) => {
        setAlertSettings({...alertSettings, 'SafeDistanceAlertSensitivity': value })
    }

    const handleChangeBlindSpotSound = (event, value) => {
        //let blindSpot = getBlindSpotValue(value)
        setAlertSettings({...alertSettings, 'BlindSpotSound': value })
    }

    const setReportAlert = (event, value) => {
        setAlertSettings({...alertSettings, 'ReportAlerts': value})
    }

    const handleSubmit = () => {
        setStatus('updating')
        const formData = new FormData();
        for (var key in alertSettings) {
            formData.append(key, key === 'BlindSpotSound' ? getBlindSpotValue(alertSettings[key]) : key="bike_id" ? alertSettings[key] : parseInt(alertSettings[key]));
        }
        RiderClient.putUpdate(rider?._id, formData, token).then((data) => {
            setMessage({title: 'Successful!', content:'Rider information has been updated succesfully!', type:'green', open:true});
            setStatus('')
            getRidersData()
        }).catch((e) => setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red',open:true}));
    }

    const handleClose = () => {
        setMessage({
            ...defaultMessage
        });
    };

    return (
        <>
            {
                alertSettings && 
                // Set pointerEvents: "none" to disable editing
                <div style={{ pointerEvents: "none" }}>
                    <Box display="flex" alignItems="center" justifyContent={"center"} className={classes.header}>
                        <Box color="text.header" textAlign={"center"}><Typography as="h1" variant="h3" className={classes.subheading}>Alerts Sensitivity Settings</Typography></Box>
                    </Box>
                    <Box alignItems="center" justifyContent={"center"} className={classes.header} >
                        <Box color="text.header" textAlign={"start"}><Typography as="h1" variant="h3" className={classes.subheading}>Collision Alert Senstivity</Typography></Box>
                        <Slider
                            value={alertSettings?.CollisionAlertSensitivity}
                            valueLabelDisplay="auto"
                            step={1}
                            size="large"
                            marks={marks}
                            min={0}
                            max={2}
                            onChange={handleChangeSensitivity}
                            />
                    </Box>

                    <Box alignItems="center" justifyContent={"center"} className={classes.header} >
                        <Box color="text.header" textAlign={"start"}><Typography as="h1" variant="h3" className={classes.subheading}>Safe Distance Alert Senstivity</Typography></Box>
                        <Slider
                            value={alertSettings?.SafeDistanceAlertSensitivity}
                            valueLabelDisplay="auto"
                            step={1}
                            size="large"
                            marks={marks}
                            min={0}
                            max={2}
                            onChange={handleChangeDistance}
                            />
                    </Box>
                    

                    <Box alignItems="center" justifyContent={"center"} className={classes.header} >
                        <Box color="text.header" textAlign={"start"}><Typography as="h1" variant="h3" className={classes.subheading}>Blind Spot Sound</Typography></Box>
                        <Slider
                            value={alertSettings?.BlindSpotSound}
                            valueLabelDisplay="auto"
                            step={1}
                            size="large"
                            marks={blindSpotSound}
                            min={0}
                            max={2}
                            onChange={handleChangeBlindSpotSound}
                            />
                    </Box>

                    <div className={classes.root}>
                        <CustomSwitch name="beacon" label="Pair bike with portal" setValue={setReportAlert} value={alertSettings.ReportAlerts == 1 ? true : false } />
                    </div>
                    {/* "Save" button was hidden coz of disabling editing on this page */}
                    <Box my={4} textAlign="center" display={"none"}>
                        <Button type="submit" className={classes.button} variant="contained" color="primary" disabled={status === 'updating'? true:false} onClick={handleSubmit}>
                            {status === 'updating'?
                                <CircularProgress color="primary" size={20}/> :
                                "Save"
                            }
                        </Button>
                    </Box>
                </div>
            }
            

            { message.open &&
                <AlertMessage
                    open={message.open}
                    close={handleClose}
                    title={message.title}
                    titleStyle={message.type}
                    ariaLabelledby="edit-rider-message"
                    ariaDescribedby="edit-rider-message">
                    {message.content}
                </AlertMessage>
            }
        </>
    )
}

export default Alerts