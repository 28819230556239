import BlackBoxClient from "../../pages/client/BlackBox";
import { getCookie } from "../../_helpers/cookies.functions";

const initialState = {
    download_counter: 0,
    requests: [],
    rider_requests: [],
}
export default function requestReducer(state = initialState, action) {
    switch (action.type) {
        case 'requests/get':
            return {...state, requests: action.payload}
        case 'requests/rider/get':
            return {...state, rider_requests: [
                        ...state.requests.filter((item) => {
                            if (item.rider_id === action.payload) {
                                return item
                            }
                        })
                    ]}
        case 'requests/add':
            return {...state, requests: [
                        action.payload,
                        ...state.requests
                    ]}
        case 'requests/delete':
            // payload to be the array id of the item to be deleted
            return {...state, requests: [
                        ...state.requests.filter((item) => {
                            if (item._id !== action.payload) {
                                return item
                            }
                        })
                    ]}
        case 'requests/update':
            // payload to be the id of the item to be deleted
            let updatedItem = action.payload
            const download_counter = action.payload.download_counter
            return {...state, download_counter, requests: state.requests.map((item)=> {
                        if (item.task_id === updatedItem.task_id) {
                            return updatedItem
                        } else {
                            return item
                        }
                    }
                  )}
        case 'requests/reset':
            return {...initialState}
        default:
            return state
    }
}



export const fetchRequest = (dispatch,getState) => {
    // const state = getState()
    const token = getCookie('token')
    console.log('fetchactivity', token)
    BlackBoxClient.getList(token).then(({data})=> {
        console.log(data)
        dispatch({type: 'requests/get', payload: data.data})
    })
    // const allActivities = getState().activity
}
