import React, {useState,useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/images/logo.png';
import { Button, Box, IconButton, AppBar} from '@material-ui/core';
import { theme } from '../theme/theme'
import Icon from '../components/icon';
import Sidebar from '../components/sidebar';

const useStyles = makeStyles({
  button: {
    borderRadius: 8,
    padding: '12px 24px',
    marginLeft: '16px',
  },
  divider: {
    backgroundColor: '#3C5558',
    margin: '8px auto',
  },
  square: {
      color: '#FFF',
      borderRadius: '8px',
      padding: theme.spacing(1),
  },
  active: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderRadius: '8px',
      boxShadow: '2px 2px 20px rgba(36, 171, 163, 0.3)',
      width: '24px',
      height: '24px',
      '&.beacon': {
        background: theme.palette.secondary.main,
      },
      '& path': {
            fill: 'white',
        }
  },
  icon: {
      width: '22px',
      height: '22px',
      fill: '#5A7679',
  },
  sos: {
    background: "linear-gradient(156.5deg, #F14545 15.15%, #FF3737 98.02%);",
    borderRadius: '10px',
    color: theme.palette.common.white,
    boxShadow: '0px 8.30769px 62.3077px #CE4545',
    textShadow: '0px 0px 2.07692px rgba(255, 255, 255, 0.4), 0px 0px 12.4615px rgba(255, 255, 255, 0.4)',
    fontWeight: 'bold',
    fontSize: '1rem',
    padding: theme.spacing(0.5,0.75),
    border: 'none',
  },
  bikeStatus: {
    background: '#141B1E',
    color: theme.palette.primary.main,
    borderRadius: '10px',
    padding: theme.spacing(1),
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    '&.offline': {
      color: theme.palette.secondary.main,
      '& path': {
            fill: theme.palette.secondary.main,
        }
    }
  },
  appBar: {
    boxShadow: 'none',
  }
})

const Header = ({rider}) => {

    const classes = useStyles();
    const [isOffline, setIsOffline] = useState(true)
    useEffect(() => {
      setIsOffline(rider?.status === 'offline' ? true : false)
    },[rider])
  
  
    // console.log(riderEmail, rider, isOffline)

      return (
        <AppBar color="transparent" position="static" className={classes.appBar}>
        <Box display="flex" p={1} justify="space-between" alignItems="center">
            <Box px={1}><img src={logo} alt="Rider Dome" width="120" height="25" /></Box>
            <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end">
              <Box mr={1}>
                <Button component={RouterLink} to="/sos"  className={classes.sos}>
                  SOS
                </Button>
              </Box>
              <Box className={isOffline ? `${classes.bikeStatus } offline` : classes.bikeStatus }>
                <Icon icon="motorcycle" color={isOffline ? theme.palette.secondary.main : theme.palette.primary.main} width={30} height={19} viewBox="0 0 30 19" /> 
                {rider?.status}
              </Box>
            </Box>
            <Sidebar rider={rider} />
        </Box>
        </AppBar>
    )
}

export default Header;