import {_request,getRequest, deleteRequest} from './Axios'

export default {
    postVideoRequest:(token,params) => {
        return _request("/upload/create-task","post",params,{
            Authorization: "Bearer " + token
        })
    },

    getList: (token,params) => {
        return getRequest("/upload/",{
            Authorization: "Bearer " + token
        },params).catch((error) => {
            console.log(error);
        })
    },

    delete: (token, params) => {
        return deleteRequest("/upload/",{
            Authorization: "Bearer " + token
        },params)
    }
}