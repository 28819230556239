import React,{useEffect} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Switch, FormControlLabel } from '@material-ui/core';
import { theme } from '../theme/theme'

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
    '& .Mui-disabled': {
      color: theme.palette.common.white,
    }
  }
}))

const IOSSwitch = withStyles({
  root: {
    width: 56,
    height: 32,
    padding: 1,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 4,
    transform: 'translateX(2px)',
    color: '#E26060',
    '&$checked': {
        transform: 'translateX(22px)',
        color: '#24ABA3',
      '& + $track': {
        backgroundColor: '#28353D',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#24ABA3',
      border: '6px solid #24ABA3',
      boxShadow: '2px 2px 20px rgba(36, 171, 163, 0.3)',
    },
  },
  thumb: {
    width: 26,
    height: 26,
  },
  track: {
    borderRadius: 32,
    border: '1px solid #28353D',
    backgroundColor: '#28353D',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
})(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CustomSwitch = ({value,name,id,putSwitch,disabled,label, setValue}) => {
    const [switchOn, setSwitchOn] = React.useState(true);
    const classes = useStyles()
    useEffect(() => {
      setSwitchOn(value);
    },[value])

  const handleChange = (event) => {
      setValue('report_alerts' , event.target.checked ? 1 : 0);
      let update = {};
      update[name] = event.target.checked;

      if (putSwitch) {
        putSwitch({update,query: { _id:id }})
      }
      setSwitchOn(event.target.checked);
    };
    return(
        <FormControlLabel
            control={<IOSSwitch checked={switchOn} onChange={handleChange}  />}
            label={label}
            labelPlacement="start"
            disabled={disabled}
            classes={
              {root: classes.label}
            }
        />
    )
};

export default CustomSwitch;