import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
    palette: {
       type: 'light',
       primary: {
           main: '#24ABA3',
           contrastText: '#FFF'
       },
       secondary: {
           light: '#E39898',
           main: '#E26060',
           dark: '#A14646',
           contrastText: '#FFF'
       },
       error: {
           main: '#E26060',
           contrastText: '#FFF'
       },
       alert: {
           main: '#E26060',
           contrastText: '#FFF'
       },
       warning: {
           main: '#FF9F47',
           contrastText: '#FFF'
       },
       success: {
           main: '#24A7A0',
           contrastText: '#273536'
       },
       text: {
           default: '#5A7679',
           primary: '#5A7679',
           secondary: '#24ABA3',
           header: '#FFF',
           muted: '#5A7679',
           light: '#4C5F69',
       },
       background: {
           default: '#194C50',
           primary: '#182021',
           secondary: '#3C5558',
           input: '#28353D',
           dark: '#273536',
           dialog: '#354A50'
       }
    },
    typography: {
        fontSize: 13,
        fontFamily: "Poppins",
        h1: { fontSize:  '2rem', fontWeight: '500'},
        h2: { fontSize: '1.8rem', fontWeight: '400'},
        h3: { fontSize: '1.6rem', fontWeight: '400'},
        h4: { fontSize: '1.25rem', fontWeight: '400'},
        h5: {fontSize: '1.1rem'},
        button: {
            textTransform: "capitalize",
        }
    },
    shape: {
        borderRadius: 8,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                background: 'linear-gradient(180deg, #151D21 0%, #222E34 100%), linear-gradient(180deg, #151D21 0%, #222E34 100%)',
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                '&.home': {
                    background: 'linear-gradient(180deg, #194C50 0%, #141B1E 34.54%), linear-gradient(180deg, #25333E 0%, #0B0F0F 100%), #141B1E',
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                }
                },
            },
        },
        MuiPickersBasePicker:{
            pickerView:{
                backgroundColor:"#28353D"
            }
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color:"white",
            },
            switchHeader: {
                justifyContent: 'center',
            },
        },
        MuiPickersDay: {
            day: {
                color:"white",
            },
            daySelected: {
                backgroundColor: "rgba(36, 171, 163, 0.34)",
                color:"white",
                borderRadius: '50%',
                border: '2px solid #24ABA3'
            },
            dayDisabled: {
                color: "#4C5F69",
            },
            current: {
                color: "white",
            },
            hidden: {
                color: "#4C5F69",
            },
        },
    },

})