import React, {useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Link, Box } from '@material-ui/core'
import Icon from '../components/icon';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { theme } from '../theme/theme'
import logo from '../assets/images/logo.png';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import VideocamIcon from '@material-ui/icons/Videocam';

const Menu = styled.div`
    display: flex;
    margin-right: 8px;
    flex-direction: column;
    padding: 1rem 1.5rem;
    width: 65vw;
`

const MenuItems = styled.ul`
    & li {
        height: 80px;
        display: flex;
        align-items: center;
    }
    margin: 0;
    padding: 0;

    &.icons {
        width: 60px;
        justify-content: center;
    }
    &.text {
        width: 0px;
        padding: 0 8px;
        color: #5A7679;
        transition: width 1s ease-in-out;
    }
    & li.active, & li.active a {
        color: #24ABA3;
    }

`
const CustomLink = styled(Link)`
    text-decoration: none;
    &, &:visited {
        color: #5A7679;
    }
    &:hover {
       color: #24ABA3;
    }
`


const useStyles = makeStyles({
  root: {
    background: "#283637",
    color: '#24ABA3',
    padding: '8px',
  },
  iconButton: {
    color: "#FFF"
  },
  square: {
      background: "#283637",
      color: '#FFF',
      borderRadius: '8px',
      padding: '0.5rem',
      width: 48,
      height: 48,
      marginRight: '1rem',
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.main,
        boxShadow: '2px 2px 20px rgba(36, 171, 163, 0.3)',
        '&.beacon': {
            background: theme.palette.secondary.main,
        },
        '& path': {
                fill: 'white',
            }
      }
  },
  sidebar: {
      minHeight: '100vh',
      height:'auto',
      background: theme.palette.background.dialog,
  },
  menuButton: {
      color: '#FFF',
      borderRadius: '8px',
      padding: theme.spacing(1),
  },
  paper: {
      background: theme.palette.background.dialog,
  }
})

const Sidebar = (props) => {
    const classes = useStyles();
    const url = new URL(window.location.href);
    const path = url.pathname.split("/");
    const [bike, setBike] = useState('')
    const [rider, setRider] = useState(props.rider)
    const [open, setOpen] = useState(false)
    
    const anchor = 'right'

    const [state, setState] = useState({
        selected: path[1],
    })
    function toggleSelected(tab) {
        setState(
            {...state, selected: tab}
        )
        setOpen(false)
    }
    const handleOpen = () => {
      setOpen(true);
    };

    const toggleDrawer = (sidebar) => (event) => {
        console.log(sidebar)
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')){
            return;
        }
        setOpen(sidebar)
    }

    return (
        <Box display="flex" alignItems="center">
                <IconButton className={classes.menuButton} onClick={handleOpen}>
                    <MenuIcon fontSize="large" />
                </IconButton>
                {open &&
                <Drawer anchor={anchor} open={open} onClose={toggleDrawer(false)} classes={{paper: classes.paper}}>
                    <div className={classes.sidebar}>
                        <Menu>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <img src={logo} alt="Rider Dome" width="110"  />
                                <IconButton onClick={toggleDrawer(false)} className={classes.iconButton}><CloseIcon /></IconButton>
                            </Box>
                            <MenuItems>
                                <li>
                                    <IconButton component={RouterLink} to="/" className={state.selected === '' ? `${classes.square} active` : classes.square} onClick={()=>{toggleSelected('');}}>
                                        <Icon icon="dashboard" color='#5A7679' />
                                    </IconButton>
                                    <CustomLink component={RouterLink} to="/" onClick={()=>toggleSelected('')}>Dashboard</CustomLink>
                                </li>
                                <li>
                                    <IconButton component={RouterLink} to="/statistics" className={state.selected === 'statistics' ? `${classes.square} active` : classes.square} onClick={()=>{toggleSelected('statistics')}}>
                                        <Icon icon="statistics" color='#5A7679' width={26} height={24} viewBox="0 0 26 24" />
                                    </IconButton>
                                    <CustomLink component={RouterLink} to="/statistics"  onClick={()=>toggleSelected('statistics')}>Statistics</CustomLink>
                                </li>
                                <li>
                                    <IconButton component={RouterLink} to={`/video`} className={state.selected === 'video' ? `${classes.square} active` : classes.square} onClick={()=>toggleSelected('video')}>
                                        <Icon icon="video" color='#5A7679' width={26} height={24} viewBox="0 0 26 24" />
                                    </IconButton>
                                    <CustomLink component={RouterLink} to={`/video`}  onClick={()=>toggleSelected('video')}>Video</CustomLink>
                                </li>
                                <li>
                                    <IconButton component={RouterLink} to={`/bike`} className={state.selected === 'bike' ? `${classes.square} active` : classes.square} onClick={()=>toggleSelected('bike')}>
                                        <Icon icon="motorcycle" color='#5A7679' width={30} height={19} viewBox="0 0 30 19" />
                                    </IconButton>
                                    <CustomLink component={RouterLink} to={`/bike`}  onClick={()=>toggleSelected('bike')}>Bike</CustomLink>
                                </li>
                                <li>
                                    <IconButton component={RouterLink} to="/sos" className={state.selected === 'beacon' ? `${classes.square} active beacon` : classes.square}  onClick={()=>toggleSelected('beacon')}>
                                        <Icon icon="beacon" color='#5A7679' width={24} height={18} viewBox="0 0 24 18" />
                                    </IconButton>
                                    <CustomLink component={RouterLink} to="/sos" onClick={()=>toggleSelected('beacon')}>SOS</CustomLink>
                                </li>
                                <li>
                                    <IconButton component={RouterLink} to={`/settings`} className={state.selected === 'settings' ? `${classes.square} active` : classes.square}  onClick={()=>toggleSelected('settings')}>
                                        <Icon icon="settings" color='#5A7679'   />
                                    </IconButton>
                                    <CustomLink component={RouterLink} to={`/settings`} onClick={()=>toggleSelected('settings')}>Settings</CustomLink>
                                </li>
                                {/* <li>
                                    <IconButton component={RouterLink} to={`/alert-senstivity`} className={state.selected === 'alert-senstivity' ? `${classes.square} active` : classes.square}  onClick={()=>toggleSelected('alert-senstivity')}>
                                        <Icon icon="alert" color='#5A7679' width={10}  />
                                    </IconButton>
                                    <CustomLink component={RouterLink} to={`/alert-senstivity`} onClick={()=>toggleSelected('alert-senstivity')}>Alert Senstivity</CustomLink>
                                </li> */}
                                <li>
                                    <IconButton component={RouterLink} to="/admin/logout" className={classes.square}>
                                        <Icon icon="logout" color='#955959' width={24} height={18} viewBox="0 0 24 18" />
                                    </IconButton>
                                    <CustomLink component={RouterLink} to="/admin/logout">Log out</CustomLink>

                                </li>
                            </MenuItems>
                        </Menu>
                    </div>
                </Drawer>
                }
            </Box>
        
    )
};

export default Sidebar;