export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function checkCookie(cname) {
  let cookie = getCookie(cname);
//   console.log(cookie)
  return cookie !== null && cookie !== ''
}

export function deleteCookie(cname) {
    const expD = new Date()
    expD.setDate(expD.getDate() - 1)
    setCookie(cname, "", expD );
}

export function getExpireTime()
{
  const now = new Date()
  now.setTime(now.getTime() + 1000*3600*24*30)
  return now
}