import React from 'react'
import { Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Header from '../components/header';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        // maxWidth: '520px',
        display: 'flex',
        flexDirection: 'column',
    },
}))

const Layout = (props) => {
    const classes = useStyles();
    const token = props.token;

    if (!token) {
      return ( <Redirect to='/login' />)
    }

    

    return (
    <Box className={classes.container} id="container">
        <Box>
            <Header token={token} rider={props.rider} />
        </Box>
        <Box flex={1} pb={3}>
            {props.children}
        </Box>
    </Box>
    )
};

export default Layout;